<template>
  <div class="coupe-de-france-du-potager max-w-xl">

    <div class="w-100 d-flex align-items-center justify-content-between">
      <b-button
          variant="outline-primary"
          @click="$router.push({name: 'coupe-de-france-du-potager'})">
        <feather-icon
            class="mr-50"
            icon="LogoutIcon"
            size="16"/>
        <span class="align-middle"> Entregistrer et quitter </span>
      </b-button>
      <b-button
          class="p-0"
          variant="flat-primary"
          @click="helpPopUp = true">
        <feather-icon
            icon="HelpCircleIcon"
            size="24"/>
      </b-button>
    </div>

    <div class="w-100 d-flex align-items-center justify-content-center">
      <img
          alt="logo coupe du monde du potager"
          class="height-100"
          src="@/assets/images/cfp/logo_coupe.png">
    </div>


    <b-row>
      <b-col
          v-for="(category, index) of categories"
          :key="index"
          cols="6"
          md="4">
        <div
            class="h-100 d-flex justify-content-center align-items-center">
          <div class="position-relative container">
            <img
                :alt="ImageDescription(category.title)"
                :src="getImage(category.title)"
                class="p-50 w-100 cursor-pointer hover-bg-primary-light"
                @click="openCategory(category)">
            <div class="position-absolute position-bottom-0 position-right-0">
              <div v-if="category.maxScore>0"
                  class="d-flex align-items-center bg-primary text-white px-50 py-25 rounded-lg m-1 mb-2">
                {{ category.score }} / {{ category.maxScore }}
                <img :alt="ImageDescription('Patate')" :src="getImage('Patate')" class="ml-50 height-20">
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <JayaPopUp
        ref="categoryPopUp"
        :name="activeCategory ? activeCategory.title : ''">
      <div v-if="activeCategory" class="w-100 max-w-xl">
        <b-row>
          <b-col cols="3">
            <b-button
                class="ml-1 p-50"
                pill
                size="sm"
                variant="dark"
                @click="$refs.categoryPopUp.closePopUp()">
              <feather-icon
                  icon="ArrowLeftIcon"
                  size="16"/>
            </b-button>
          </b-col>
          <b-col class="d-flex justify-content-center" cols="6">
            <img :alt="ImageDescription(activeCategory.title)" :src="getImage(activeCategory.title)" style="max-width:100%;max-height:200px;">
          </b-col>
        </b-row>

        <div v-for="(text, index) in activeCategory.text" :key="activeCategory.title + index">
          <p>{{ text }}</p>
        </div>

        <b-card
            v-for="(subcategory, index) in activeCategory.subcategories"
            :key="index"
            body-class="p-25"
            class="cursor-pointer hover-bg-primary-light"
            @click="openQuiz(subcategory)">
          <div class="w-100 pl-1 d-flex justify-content-between align-items-center">
            <span class="font-weight-bold"> {{ subcategory.title }}</span> <br>
            <div class="float-right m-50 mr-1">
              <!-- <span>{{subcategory.end - subcategory.start + 1}} que  stions</span> -->
              <div class="d-flex text-lg bg-primary text-white px-1 py-50 rounded-lg">
                {{ subcategory.score }} / {{ subcategory.maxScore }}
                <img :alt="ImageDescription('Patate')" :src="getImage('Patate')" class="ml-25 height-20">
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </JayaPopUp>

    <JayaPopUp
        ref="quizPopUp"
        :name="activeSubcategory ? activeSubcategory.title : ''"
        @close="openCategory(activeCategory)">
      <div v-if="activeCategory && activeSubcategory" class="w-100 max-w-xl">
        <h3 class="mt-3">
          {{ activeSubcategory.title }}
        </h3>
        <CDFDPQuiz :criteria="activeSubcategory.criteria" @close="$refs.quizPopUp.closePopUp()"/>
      </div>
    </JayaPopUp>

    <b-modal
        v-model="helpPopUp"
        centered
        hide-footer
        title="Besoin d'aide">
      <div class="d-flex align-items-center">
        <img
            alt="Icone de feuille"
            class="mx-1"
            src="@/assets/images/icons/other/question_bubbles.svg"
            style="height: 35px;min-width:40px;">
        <p class="p-0 m-0">
          Une question ou un commentaire sur l’évaluation et ses questions ? Écrivez à l’adresse
          <br>
          <a class="underline" href="mailto:coupedefrancedupotager@landestini.org">
            coupedefrancedupotager&#64;landestini&#46;org
          </a>
        </p>
      </div>
      <div class="d-flex align-items-center pt-50">
        <img
            alt="Icone de feedback negatif"
            class="mx-1"
            src="@/assets/images/icons/other/feedback_negative.svg"
            style="height: 35px;min-width:40px;">
        <p class="p-0 m-0">
          Un problème technique dans le formulaire d’évaluation ? Ecrivez à
          <br>
          <a class="underline" href="mailto:contact@hortilio.fr">
            contact&#64;hortilio&#46;fr
          </a>
        </p>
      </div>
    </b-modal>
  </div>
</template>


<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import JayaPopUp from '@/components/global/JayaPopUp.vue'
import CDFDPQuiz from '@/components/cfp/CDFDPQuiz.vue'
import {BButton, BCard, BCol, BModal, BRow} from "bootstrap-vue";

export default {
  name: 'coupe-de-france-du-potager',
  components: {
    BButton,
    BRow,
    BCol,
    BModal,
    BCard,
    FormWizard,
    TabContent,
    JayaPopUp,
    CDFDPQuiz
  },
  data() {
    return {
      quizPopUp: false,
      activeCategory: null,
      activeSubcategory: null,
      saveGuardPrompt: false,
      unsavedModifications: false,
      helpPopUp: false,

      categories: [
        {
          title: 'LaBiodiversiteCestCarre',
          score: null,
          maxScore: null,
          text: [
            'La biodiversité est l’une des clés pour un potager en bonne santé, et le potager est un outil permettant de régénérer la biodiversité ! Le soin et l’attention que vous avez apportés à la biodiversité sont donc des critères importants dans la Coupe de France du potager.',
            'La biodiversité dans le potager, ce sont par exemple les insectes et animaux visibles, mais aussi les bactéries et champignons dans le sol. Pour protéger et favoriser toutes ces espèces, vous pouvez miser sur la diversité des végétaux, afin de les abriter et de les nourrir, ou bien installer des nichoirs, ou une haie champêtre… Travailler le sol de façon modérée permet aussi de ne pas perturber l’écosystème du sous-sol.',
            'Pour rappel, vous pouvez traiter les questions ci-dessous en plusieurs fois. Vos réponses seront enregistrées progressivement. Pour chaque question, vous avez la possibilité d’ajouter un ou plusieurs justificatifs photo. Ces justificatifs sont facultatifs, mais renforcent votre dossier pour l’examen par le jury. Nous vous encourageons donc à en soumettre un maximum (les mêmes photos peuvent être utilisées plusieurs fois si elles sont pertinentes pour plusieurs questions). Plus  vous appuyez votre dossier avec des justificatifs photos, plus il sera robuste !'
          ],
          subcategories: [
            {
              title: 'LES PLANTES POTAGERES',
              criteria: [
                "Des plantes potagères diversifiées",
                "Des semences et plants pas comme les autres"
              ],
              score: null,
              maxScore: null
            },
            {
              title: 'LA BIODIVERSITE EN DEHORS DU POTAGER',
              criteria: [
                "La mise en place d'aménagements qui favorisent la biodiversité (mare, hôtel à insecte, nichoir, poulailler...)",
                "La plantation de fleurs mellifères"
              ],
              score: null,
              maxScore: null
            },
            {
              title: 'LE SOL',
              criteria: [
                "Un travail du sol modéré",
              ],
              score: null,
              maxScore: null
            },
            {
              title: 'CONNAITRE LA BIODIVERSITE',
              criteria: [
                "Observation des espèces sauvages et répertoire de la biodiversité",
              ],
              score: null,
              maxScore: null
            },
            {
              title: 'PARTENARIAT AVEC UN MUSEUM D\'HISTOIRE NATUREL',
              criteria: [
                "Bonus",
              ],
              score: null,
              maxScore: null
            },
            {
              title: 'QUESTION LIBRE',
              criteria: [
                "Question libre – La biodiversité c’est carré"
              ],
              score: null,
              maxScore: null
            }
          ]
        },
        {
          title: 'CaNousNourrit',
          score: null,
          maxScore: null,
          text: [
            'Le potager, cela sert en premier lieu à produire de la nourriture ! Que votre potager soit petit ou grand, ce critère vous permet de nous montrer votre travail pour favoriser sa productivité. Avez-vous créé des aménagements verticaux pour rentabiliser l’espace à votre disposition ? Avez-vous utilisé des engrais naturels, et associés des cultures (compagnonnage) ? Comment comptez-vous utiliser les récoltes du potager ? Voilà autant de questions auxquelles vous pourrez répondre dans cette partie ! ',
            'Pour rappel, vous pouvez traiter les questions ci-dessous en plusieurs fois. Vos réponses seront enregistrées progressivement. Pour chaque question, vous avez la possibilité d’ajouter un ou plusieurs justificatifs photo. Ces justificatifs sont facultatifs, mais renforcent votre dossier pour l’examen par le jury. Nous vous encourageons donc à en soumettre un maximum (les mêmes photos peuvent être utilisées plusieurs fois si elles sont pertinentes pour plusieurs questions). Plus  vous appuyez votre dossier avec des justificatifs photos, plus il sera robuste !'
          ],
          subcategories: [
            {
              title: 'PRODUCTIVITE',
              criteria: [
                "Quantité récoltée en kg",
                "Diversité de la récolte",
                "Meilleure production au m²",
                "Maximisation des zones productives",
              ],
              score: null,
              maxScore: null
            },
            {
              title: 'ENGRAIS AU NATUREL',
              criteria: [
                "Un sol riche au naturel !",
              ],
              score: null,
              maxScore: null
            },
            {
              title: 'DISTRIBUTION',
              criteria: [
                "Utilisation et distribution des récoltes",
              ],
              score: null,
              maxScore: null
            },
            {
              title: 'COMPOSTEUR',
              criteria: [
                "Compost au sein de la structure",
              ],
              score: null,
              maxScore: null
            }
          ]
        },
        {
          title: 'MonBoPotager',
          score: null,
          maxScore: null,
          text: [
            'C’est un critère incontournable : la beauté de votre potager ! ',
            'Avez-vous réfléchi en équipe au design de votre potager ? Avez-vous planté des espèces non potagères pour la beauté de votre jardin ? Avez-vous agrémenté votre potager d\'œuvres artistiques, ou d’un espace détente ? Nous voulons tout savoir !',
            'Pour rappel, vous pouvez traiter les questions ci-dessous en plusieurs fois. Vos réponses seront enregistrées progressivement. Pour chaque question, vous avez la possibilité d’ajouter un ou plusieurs justificatifs photo. Ces justificatifs sont facultatifs, mais renforcent votre dossier pour l’examen par le jury. Nous vous encourageons donc à en soumettre un maximum (les mêmes photos peuvent être utilisées plusieurs fois si elles sont pertinentes pour plusieurs questions). Plus  vous appuyez votre dossier avec des justificatifs photos, plus il sera robuste !'
          ],
          subcategories: [
            {
              title: 'REFLEXION COLLECTIVE',
              criteria: [
                "Design réfléchi collectivement",
              ],
              score: null,
              maxScore: null
            },
            {
              title: 'AMENAGEMENTS DES ESPACES POTAGERS',
              criteria: [
                "Beauté",
                "Originalité",
                "Fleur et couleurs",
              ],
              score: null,
              maxScore: null
            },
            {
              title: 'ART',
              criteria: [
                "La création d'oeuvres artistiques autour du potager",
              ],
              score: null,
              maxScore: null
            },
            {
              title: 'AMENAGEMENT DETENTE',
              criteria: [
                "Bonus Mon Bo' Potager",
              ],
              score: null,
              maxScore: null
            }
          ]
        },
        {
          title: 'OnEstPrets',
          score: null,
          maxScore: null,
          text: [
            'Les effets du changement climatique se ressentent dans les jardins (déficit en eau, bouleversement des saisons...). Ces phénomènes obligent les potagistes à s’adapter, par exemple dans le calendrier des semis, et même dans le choix des espèces. ',
            'En même temps, le potager est un outil important pour lutter à son échelle contre le changement climatique, en participant notamment au stockage du carbone.',
            'Avez-vous pris en compte le climat de votre territoire dans la conception de votre potager, comme dans le choix des plantes par exemple ? Avez-vous réalisé des expériences pour connaître la texture de votre sol ? Avez-vous prévu une rotation des cultures, et réfléchi à un système d’arrosage économe ? Dites-nous tout !',
            'Pour rappel, vous pouvez traiter les questions ci-dessous en plusieurs fois. Vos réponses seront enregistrées progressivement. Pour chaque question, vous avez la possibilité d’ajouter un ou plusieurs justificatifs photo. Ces justificatifs sont facultatifs, mais renforcent votre dossier pour l’examen par le jury. Nous vous encourageons donc à en soumettre un maximum (les mêmes photos peuvent être utilisées plusieurs fois si elles sont pertinentes pour plusieurs questions). Plus  vous appuyez votre dossier avec des justificatifs photos, plus il sera robuste !'
          ],
          subcategories: [
            {
              title: 'CONNAISSANCE DE MON JARDIN ET DE SON ENVIRONNEMENT',
              criteria: [
                "Connaître son climat et déterminer l'emplacement du potager",
                "Connaître son sol",
              ],
              score: null,
              maxScore: null
            },
            {
              title: 'UN POTAGER SUR LE LONG TERME',
              criteria: [
                "Organisation du potager réflechie",
                "Système de rotation des cultures",
              ],
              score: null,
              maxScore: null
            },
            {
              title: 'GESTION DE L\'EAU',
              criteria: [
                "Système d'arrosage intelligent",
              ],
              score: null,
              maxScore: null
            },
            {
              title: 'AUTONOMIE ALIMENTAIRE',
              criteria: [
                "Echange et production de semences",
                "Conservation des récoltes",
              ],
              score: null,
              maxScore: null
            }
          ]
        },
        {
          title: 'EnsembleOnVaPlusLoin',
          score: null,
          maxScore: null,
          text: [
            'Le potager permet de créer un projet collectif et inclusif : c’est aussi l’objectif de ce concours. Votre équipe est-elle intergénérationnelle ? Combien de personnes sont venues vous donner un coup de main (parents d’élèves, voisins, cuisiniers de votre structure…) ? Votre potager est-il accessible aux personnes à mobilité réduite ? Avez-vous collaboré avec votre mairie, ou une association locale, par exemple pour obtenir du matériel ? Avez-vous récupéré des graines ou plants via un groupe d’échange, ou grâce à un voisin ? Partager et échanger : c’est cela aussi, la Coupe de France du potager !',
            'Pour rappel, vous pouvez traiter les questions ci-dessous en plusieurs fois. Vos réponses seront enregistrées progressivement. Pour chaque question, vous avez la possibilité d’ajouter un ou plusieurs justificatifs photo. Ces justificatifs sont facultatifs, mais renforcent votre dossier pour l’examen par le jury. Nous vous encourageons donc à en soumettre un maximum (les mêmes photos peuvent être utilisées plusieurs fois si elles sont pertinentes pour plusieurs questions). Plus  vous appuyez votre dossier avec des justificatifs photos, plus il sera robuste !'
          ],
          subcategories: [
            {
              title: 'ACCESSIBILITE A TOUS',
              criteria: [
                "Accessibilité aux personnes en situation de handicap",
              ],
              score: null,
              maxScore: null
            },
            {
              title: 'DIVERSITE DES PERSONNES AU SEIN DE L\'EQUIPE',
              criteria: [
                "Âge des participants diversifié",
              ],
              score: null,
              maxScore: null
            },
            {
              title: 'DIVERSITE DES PERSONNES IMPLIQUEES EN DEHORS DE L\'EQUIPE',
              criteria: [
                "Participation diversifiée de personnes en dehors de l'équipe",
              ],
              score: null,
              maxScore: null
            },
            {
              title: 'ECHANGER POUR ALLER PLUS LOIN',
              criteria: [
                "Collaboration avec mairie, association, particulier... pour fournir du matériel",
                "Échange de semences/plants",
                "Échange de savoirs et savoir-faire",
              ],
              score: null,
              maxScore: null
            }
          ]
        },
        {
          title: 'JApprendsJePartage',
          score: null,
          maxScore: null,
          text: [
            'La Coupe de France du potager, c’est aussi un moyen de créer une communauté autour de votre aventure au potager ! Comment ? En installant des panneaux pédagogiques en extérieur pour présenter vos plantations et vos installations, en organisant un événement dans le potager, ou en créant un blog, une page Facebook, etc, pour partager votre aventure… L’objectif : impliquer le plus de personnes possible dans votre projet ! ',
            'Pour rappel, vous pouvez traiter les questions ci-dessous en plusieurs fois. Vos réponses seront enregistrées progressivement. Pour chaque question, vous avez la possibilité d’ajouter un ou plusieurs justificatifs photo. Ces justificatifs sont facultatifs, mais renforcent votre dossier pour l’examen par le jury. Nous vous encourageons donc à en soumettre un maximum (les mêmes photos peuvent être utilisées plusieurs fois si elles sont pertinentes pour plusieurs questions). Plus  vous appuyez votre dossier avec des justificatifs photos, plus il sera robuste !'
          ],
          subcategories: [
            {
              title: 'A l\'INTERIEUR DE L\'ETABLISSEMENT',
              criteria: [
                "Affichages explicatifs à l'intérieur de l'établissement",
                "Affichages explicatifs au potager",
              ],
              score: null,
              maxScore: null
            },
            {
              title: 'A L\'EXTERIEUR DE L\'ETABLISSEMENT',
              criteria: [
                "Partage au grand public",
                "Organisation d'un évènement",
              ],
              score: null,
              maxScore: null
            }
          ]
        },
        {
          title: 'QuelleDebrouillardise',
          score: null,
          maxScore: null,
          text: [
            'Vous avez créé un potager avec peu de moyens ? Les infrastructures à votre disposition n’étaient vraiment pas propices à la création et l’entretien d’un potager ? Vous avez rusé, utilisé votre créativité pour surmonter un déficit de budget ou de matériel, et vous êtes fier.e du résultat ? Toutes nos félicitations ! Dans cette partie, vous pourrez nous faire part de vos difficultés, et de comment vous êtes parvenu à les dépasser.',
            'L\'équipe Landestini relira tous les textes sélectionnera les 5 dossiers ayant fait preuve de la plus grande débrouillardise pour être évalué par le jury.',
            'Pour rappel, vous pouvez traiter les questions ci-dessous en plusieurs fois. Vos réponses seront enregistrées progressivement. Pour chaque question, vous avez la possibilité d’ajouter un ou plusieurs justificatifs photo. Ces justificatifs sont facultatifs, mais renforcent votre dossier pour l’examen par le jury. Nous vous encourageons donc à en soumettre un maximum (les mêmes photos peuvent être utilisées plusieurs fois si elles sont pertinentes pour plusieurs questions). Plus  vous appuyez votre dossier avec des justificatifs photos, plus il sera robuste !'
          ],
          subcategories: [
            {
              title: 'QuelleDebrouillardise',
              criteria: [
                "Quelle Débrouillardise",
              ],
              score: null,
              maxScore: null
            }
          ]
        },
        {
          title: 'MiniPousses',
          score: null,
          maxScore: null,
          text: [
            'Si votre potager est de 6 à 10m2 (inclus) seulement, répondez OUi à la question ci-dessous, et indiquez la taille du potager ! Le prix mini-pousses sera décerné aux 4 potagers (un par catégorie) avec les meilleures évaluations. ',
            'Pour rappel, vous pouvez traiter les questions ci-dessous en plusieurs fois. Vos réponses seront enregistrées progressivement. Pour chaque question, vous avez la possibilité d’ajouter un ou plusieurs justificatifs photo. Ces justificatifs sont facultatifs, mais renforcent votre dossier pour l’examen par le jury. Nous vous encourageons donc à en soumettre un maximum (les mêmes photos peuvent être utilisées plusieurs fois si elles sont pertinentes pour plusieurs questions). Plus  vous appuyez votre dossier avec des justificatifs photos, plus il sera robuste !'
          ],
          subcategories: [
            {
              title: 'MiniPousses',
              criteria: [
                "Mini Pousses",
              ],
              score: null,
              maxScore: null
            }
          ]
        },
        {
          title: 'BonusBonus',
          score: null,
          maxScore: null,
          text: [
            'Vous êtes fiers d’un élément ou d’une caractéristique de votre potager en particulier, et nos questions ne vous ont pas permis d’en parler ? Dites-nous tout ici !',
            'Pour rappel, vous pouvez traiter les questions ci-dessous en plusieurs fois. Vos réponses seront enregistrées progressivement. Pour chaque question, vous avez la possibilité d’ajouter un ou plusieurs justificatifs photo. Ces justificatifs sont facultatifs, mais renforcent votre dossier pour l’examen par le jury. Nous vous encourageons donc à en soumettre un maximum (les mêmes photos peuvent être utilisées plusieurs fois si elles sont pertinentes pour plusieurs questions). Plus  vous appuyez votre dossier avec des justificatifs photos, plus il sera robuste !'
          ],
          subcategories: [
            {
              title: 'BonusBonus',
              criteria: [
                "Bonus Bonus"
              ],
              score: null,
              maxScore: null
            }
          ]
        }
      ]
    }
  },
  mounted() {
    this.$loading(true)
    this.$store.dispatch("feedback/getQuiz", {type: "Coupe de france du potager"})
        .then(() => {
          this.updateScores()
          this.$store.dispatch("feedback/getQuizAnswers")
              .then(() => {
                this.$loading(false)
              })
              .catch(() => {
                this.$notify({
                  time: 4000,
                  title: "Erreur",
                  text: "Veuillez réessayer ulterieurement",
                  color: "danger"
                })
                this.$loading(false)
              })
        })
        .catch(() => {
          this.$notify({
            time: 4000,
            title: "Erreur",
            text: "Veuillez réessayer ulterieurement",
            color: "danger"
          })
          this.$loading(false)
          this.$router.push({name: 'coupe-de-france-du-potager'})
        })
  },
  methods: {
    openCategory(category) {
      this.activeCategory = category
      this.$refs.categoryPopUp.openPopUp()
    },
    openQuiz(subCategory) {
      this.activeSubcategory = subCategory
      this.$refs.categoryPopUp.closePopUp()
      this.$refs.quizPopUp.openPopUp()
    },
    stepBack() {
      if (this.activeSubcategory) {
        this.unsavedModifications ? this.saveGuardPrompt = true : this.activeSubcategory = null
      } else {
        this.quizPopUp = false
      }
    },
    getImage(image) {
      let img = ""
      try {
        img = require("@/assets/images/cfp/" + image + ".png")
      } finally {
        // do nothing
      }
      return img
    },
    ImageDescription(object) {
      return "Image d'un pictogramme de " + object
    },
    updateScores() {
      this.$store.dispatch("feedback/getCDFPDScore", {type: "CF"})
          .then(response => {
            let scores = response.data
            for (const category of this.categories) {
              let categoryScore = this.calcScore(scores, this.getCriteria(category))
              category.score = categoryScore.user
              category.maxScore = categoryScore.max
              for (const subcatergory of category.subcategories) {
                let subcategoryScore = this.calcScore(scores, subcatergory.criteria)
                subcatergory.score = subcategoryScore.user
                subcatergory.maxScore = subcategoryScore.max
              }
            }
          })
          .catch(() => {
          })
    },
    calcScore(scores, criteriaList) {
      let max = 0
      let user = 0
      let usefulScores = scores.filter(item => criteriaList.includes(item.criteria))
      for (const score of usefulScores) {
        max += score.max_score
        user += score.score
      }
      return {
        max: max,
        user: user
      }
    },
    getCriteria(category) {
      let resultArray = []
      for (const subcategory of category.subcategories) {
        for (const criteria of subcategory.criteria) {
          resultArray.push(criteria)
        }
      }
      return resultArray
    },
  }
}
</script>
