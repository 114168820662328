<template>
  <div class="cdfdp-quiz">
    <b-alert class="text-center" variant="danger">
      La date butoire est passée. Vous n'avez plus la possibilité de modifier vos réponses.
    </b-alert>
    <form-wizard
        :subtitle="null"
        :title="null"
        backButtonText="Précédent"
        class="p-0"
        color="var(--primary)"
        errorColor="(--danger)"
        finishButtonText="Femer"
        nextButtonText="Suivant"
        stepSize="xs"
        @on-complete="$emit('close')">
      <tab-content
          v-for="(question, index) in questions"
          v-if="question.conditionnal_answer==null"
          :key="index"
          class="mb-4">
        <h2 class="w-100 text-center text-primary">{{ question.title }}</h2>

        <div class="font-medium-3 mb-2 font-weight-bold text-dark">
          <div v-if="question.title == 'Des plantes potagères diversifiées' || question.title == 'Des semences et plants pas comme les autres'">
            Veuillez indiquer dans votre potager le :
          </div>
          <div v-if="question.title == 'Quelle Débrouillardise'">
            Pour tous les potagers qui sont partis de rien et/ ou si vous êtes particulièrement fiers de la
            débrouillardise dont vous avez fait preuve, vous pouvez nous parler de votre démarche dans la zone de texte
            ci-dessous ! Pour appuyer votre propos, vous pouvez également insérer un justificatif photo
            (une seule photo ou un montage photo).
          </div>
        </div>

        <div
            v-for="(subquestion, index) in question.subquestions"
            :key="index"
            class="mb-2">
          <div class="mb-1">
            <span class="font-bold text-lg">{{ subquestion.text }}</span>
            <span v-if="subquestion.score !== 0">
              (
              {{ subquestion.type == 'NU' ? 'x' : '+' }}{{ subquestion.score }}
              <img :alt="ImageDescription('Patate')" :src="getImage('Patate')" class="height-20">
              )
            </span>
          </div>
          <div class="d-flex flex-wrap align-items-center">
            <b-col
                :class="(subquestion.justification === 'PJ' || subquestion.justification === 'VJ') ? 'col-md-8' : ''"
                class="col-12 d-flex justify-content-center">
              <div v-if="subquestion.type === 'YN'">
                <b-form-checkbox
                    v-model="quizAnswers[subquestion.order]"
                    class="custom-control-primary"
                    disabled
                    switch>
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon"/>
                    </span>
                </b-form-checkbox>
              </div>
              <div v-if="subquestion.type === 'NU'">
                <b-form-input
                    v-model="quizAnswers[subquestion.order]"
                    disabled
                    min="0"
                    type="number"/>
              </div>
              <div v-if="subquestion.type === 'TE'" class="w-100">
                <b-form-textarea
                    v-model="quizAnswers[subquestion.order]"
                    disabled/>
              </div>
            </b-col>

            <b-col
                v-if="(subquestion.justification === 'PJ' || subquestion.justification === 'VJ')
                && quizJustifications[subquestion.order] && quizJustifications[subquestion.order].length > 0"
                class="col-12 col-md-4 d-flex justify-content-center">
              <b-button
                  :variant="quizJustifications[subquestion.order] && quizJustifications[subquestion.order].length > 0 ? 'outline-primary' : 'outline-secondary'"
                  @click="openJustification(subquestion)">
                <feather-icon
                    class="mr-50"
                    icon="CameraIcon"
                    size="16"/>
                <span class="align-middle">
                  Justificatif
                  {{
                    quizJustifications[subquestion.order] && quizJustifications[subquestion.order].length > 0 ? '(x' + quizJustifications[subquestion.order].length + ')' : ''
                  }}
                  </span>
              </b-button>
            </b-col>
          </div>
        </div>
      </tab-content>
    </form-wizard>


    <b-modal
        v-if="activeSubquestion && quizJustifications[activeSubquestion.order]"
        v-model="justificationPopUp"
        centered
        hide-footer
        title="Justification">
      <h5 class="mb-3">Justification pour "{{ activeSubquestion.text }}"</h5>

      <div v-if="quizJustifications[activeSubquestion.order].length > 0">
        <div class="flex items-center mb-3">
          <h2> Vos justifications (x{{ quizJustifications[activeSubquestion.order].length }}) : </h2>
        </div>

        <div class="flex justify-center flex-wrap">
          <div v-for="(photo, index) in quizJustifications[activeSubquestion.order]" :key="index" class="flex justify-center flex-wrap w-full">
            <div class="relative">
              <h3 class="absolute top-0 left-0 m-2 bg-white pr-2 pl-2 rounded-full bg-opacity-75">{{ index + 1 }}</h3>
              <img :src="photo.url" class="h-64"/>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BAlert,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormTextarea,
  BModal,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BFormRadioGroup,
    BFormGroup,
    BButton,
    BCol,
    BFormCheckbox,
    BFormInput,
    BFormTextarea,
    BModal,
    BAlert,
    FormWizard,
    TabContent
  },
  props: {
    questionNumbers: {
      type: Object,
      default: null
    },
    criteria: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      quizAnswers: [],
      quizJustifications: [],
      allQuestionsWithinCriteria: [],
      questions: null,
      allQuestions: null,
      startQuestion: null,
      endQuestion: null,
      quizId: null,
      activeQuestionList: [],
      activeQuestion: 0,
      activeSubquestion: null,
      activePhoto: null,
      mountedFinised: false,
      justificationPopUp: false,
      uploadPhotoPrompt: false,
      removePhotoPrompt: false,
      userAnswerId: null
    }
  },
  mounted() {
    this.quizId = this.$store.state.feedback.cdfQuiz.id
    this.allQuestions = this.$store.state.feedback.cdfQuiz.questions
    let questionCount = 0
    this.allQuestionsWithinCriteria = this.allQuestions.filter(question => this.criteria.includes(question.title)).sort((a, b) => (a.order - b.order))

    // get indexes of allQuestionsWithinCriteria in allQuestions
    this.startQuestion = this.allQuestions.findIndex(question => this.criteria.includes(question.title))
    this.endQuestion = this.allQuestions.findIndex(question => this.criteria.includes(question.title)) + this.allQuestionsWithinCriteria.length - 1

    this.questions = []
    for (const questionInCriteria of this.allQuestionsWithinCriteria) {
      //look for same name in questions
      if (!this.questions.some(question => question.title == questionInCriteria.title)) {
        //if no other same name : make questions object with this question title and this question in 'subquestions'
        this.questions.push({title: questionInCriteria.title, order: questionCount, subquestions: [questionInCriteria]})
        questionCount++
      } else {
        //add question to same title question subquestions
        this.questions.find(question => question.title == questionInCriteria.title).subquestions.push(questionInCriteria)
      }
    }
    let lastUpdate = []
    if (this.$store.state.feedback.cdfAnswers) {
      this.userAnswerId = this.$store.state.feedback.cdfAnswers.id
      lastUpdate = this.$store.state.feedback.cdfAnswers.answers_given
    }
    for (let question of this.allQuestionsWithinCriteria) {
      let questionPreviousAnswer = lastUpdate.some(el => el.question.order == question.order) ? lastUpdate.find(el => el.question.order == question.order) : null
      if (question.type == 'NU') {
        questionPreviousAnswer ? this.quizAnswers[question.order] = questionPreviousAnswer.number_answer : this.quizAnswers[question.order] = null
      } else if (question.type == 'YN') {
        questionPreviousAnswer ? this.quizAnswers[question.order] = questionPreviousAnswer.boolean_answer : this.quizAnswers[question.order] = false
      } else if (question.type == 'TE') {
        questionPreviousAnswer ? this.quizAnswers[question.order] = questionPreviousAnswer.text_answer : this.quizAnswers[question.order] = ''
      } else {
        questionPreviousAnswer ? this.quizAnswers[question.order] = questionPreviousAnswer.answer : this.quizAnswers[question.order] = null
      }
      if (question.conditionnal_answer == null) {
        this.activeQuestionList.push(question)
      }
      let questionJustifications = []
      if (questionPreviousAnswer) {
        for (const image of questionPreviousAnswer.justifications) {
          questionJustifications.push({url: process.env.VUE_APP_BACKEND_URL + '/..' + image.photo_url, id: image.image})
        }
      }
      this.quizJustifications[question.order] = questionJustifications
    }

  },
  methods: {
    validateContent() {
      let question = this.activeQuestionList[this.activeQuestion]
      let value = this.quizAnswers[question.order]
      /*
        if the question is mandatory, it can'be an empty array
        or null if it cannot have more than one.
      */
      if (question.is_mandatory) {
        if (!value || (value instanceof Array && value.length == 0)) {
          return false
        }
      }
      return true
    },
    changeActive(event) {
      this.activeQuestion = event
    },
    getImage(image) {
      let img = ""
      try {
        img = require("@/assets/images/cfp/" + image + ".png")
      } finally {
        // do nothing
      }
      return img
    },
    ImageDescription(object) {
      return "Image d'un pictogramme de " + object
    },
    openJustification(subquestion) {
      this.activeSubquestion = subquestion
      this.justificationPopUp = true
    }
  }
}
</script>

<style lang="scss">
.cdfdp-quiz {
  width: 100%;
  height: 100%;

  .wizard-header {
    padding: 0;
  }

  .checkbox-quiz-element {
    margin-bottom: 8px;
    font-size: 16px;
  }

  .vue-form-wizard .wizard-card-footer {
    padding: 0 10px;
  }

  .wizard-tab-content {
    padding-top: 10px;
  }
}
</style>
